import React, { ComponentProps, ReactNode } from "react";
import classNames from "classnames";
import { JsonLd } from "react-schemaorg";
import { BreadcrumbList, ListItem } from "schema-dts";

import useDomain from "hooks/useDomain";

import Breadcrumb from "components/Breadcrumb";

const Breadcrumbs: React.FC<{
  children?: React.ReactNode;
  className?: string;
  orderedListClassNames?: string;
  useWhiteBackground?: boolean;
}> = ({
  children,
  className,
  orderedListClassNames,
  useWhiteBackground = true,
}) => {
  const domain = useDomain();

  const crumbs: ReactNode[] = [
    <Breadcrumb key="home" href="/">
      Home
    </Breadcrumb>,
    ...React.Children.toArray(children),
  ];

  return (
    <nav
      aria-label="breadcrumbs"
      className={classNames(
        "overflow-x-auto relative",
        { "bg-white fade-white-right md:after:hidden": useWhiteBackground },
        className,
      )}
      data-testid="breadcrumbs"
    >
      <ol
        className={classNames(
          "container flex flex-wrap items-center overflow-x-auto pl-0 mb-lg mt-md",
          orderedListClassNames,
        )}
      >
        {crumbs}
      </ol>

      <JsonLd<BreadcrumbList>
        item={{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: generateJsonLdFromCrumbs(crumbs, domain),
        }}
      />
    </nav>
  );
};

/**
 * Generates structured schema entries for each breadcrumb component by iterating
 * over each component and extracting the relevant props. This process will skip
 * any elements that are not Breadcrumb components, though these should not
 * reasonably appear within the Breadcrumbs component's children if used as intended.
 */
const generateJsonLdFromCrumbs = (
  children: ReactNode[],
  domain: string,
): BreadcrumbList["itemListElement"] => {
  const crumbsJson: ListItem[] = [];

  React.Children.forEach(children, (child, index) => {
    if (
      React.isValidElement<ComponentProps<typeof Breadcrumb>>(child) &&
      child.type === Breadcrumb
    ) {
      try {
        crumbsJson.push({
          "@type": "ListItem",
          item: child.props.href
            ? new URL(child.props.href, domain).toString()
            : undefined,
          name: String(child.props.children),
          position: 1 + index,
        });
      } catch (e) {
        if (e instanceof TypeError) {
          /**
           * The URL constructor will throw a type error if the domain is empty
           * or malformed. To guard against these misconfigurations, bail out
           * of building JSON.
           */
          return;
        }

        throw e;
      }
    }
  });

  return crumbsJson;
};

export default Breadcrumbs;
